import { Injectable,ErrorHandler } from '@angular/core';
import { MonitoringService } from '@app/shared/logging/logging.service';

@Injectable({
  providedIn : "root"
})
export class ErrorHandlerService extends ErrorHandler {

    constructor(private monitoringService: MonitoringService) {
        super();
    }

    override handleError(error: Error) {
        this.monitoringService.logException(error); // Manually log exception
        super.handleError(error);
    }
}