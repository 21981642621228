import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { AlertService } from 'services/alert-service/alert.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          if (error.status === 401 || error.status === 403) {
            this.alertService.error(
              'Unauthorized access - Sorry, your request could not be processed!'
            );
          } else if (error.status === 500 && error.error?.Message) {
            this.alertService.error(
              error.error?.Message
            );
          }
          else {
            this.alertService.error(
              'An unexpected error occurred, please contact support if the issue persists.'
            );
          }
        }

        return throwError(() => error);
      })
    );
  }
}
