import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AuthService, getUserId } from 'services/auth-service/auth.service';





var addApplicationName = (envelope : ITelemetryItem)=> {
  try{
    if(envelope !== undefined && envelope.baseData !== undefined && !!!envelope.baseData['properties']['Application Name'] ){
      envelope.baseData['properties']['Application Name'] = 'LinkPortal';
    }
    return true;
  }
  catch{
    return true;
  }
}

var addUserID = (envelope : ITelemetryItem)=> {
  AuthService
  try{
    if(envelope !== undefined && envelope.baseData !== undefined && !!!envelope.baseData['properties']['User ID'] ){
      envelope.baseData['properties']['User ID'] = getUserId() || "Unauthorized";
    }
    return true;
  }
  catch{
    return true;
  }
}


@Injectable()
export class MonitoringService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes,
        
      }
    });
    this.appInsights.addTelemetryInitializer(addApplicationName);
    this.appInsights.addTelemetryInitializer(addUserID);
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}