import { createReducer, on } from '@ngrx/store';
import { setFeatureFlag } from './feature-flag.action';

export interface FeatureFlagInterface {
  flag: string;
  state: boolean;
}

export const initialState: FeatureFlagInterface[] = [];

export const flagsReducer = createReducer(
  initialState,
  on(setFeatureFlag, (state, { flags }) => {
    return flags;
  })
);
