import { createReducer,on } from '@ngrx/store';
import { RoleItem } from '../models/role-item';
import { setRoles } from '../actions/roles.action';

//create a dummy initial state
export const initialState: RoleItem = 
{
    userRoles: [],
};

export const roleReducer = createReducer(
    initialState,
    on(setRoles, (state,{roles}) => {
        let obj = Object.assign({},state);
        obj.userRoles = roles;
        return obj;
    }),
);