import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "services/auth-service/auth.service";

@Injectable()
export class AuthRedirectService  {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(): boolean {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
