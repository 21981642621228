import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import "./locales/importLocales";

import { BrowserModule } from '@angular/platform-browser';
import { TableauModule } from 'ngx-tableau';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
const {
  CustomDatepickerFormat,
} = require('./shared/components/input/custom-datepicker/config/custom-datepicker-formats');
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuardService } from 'services/auth-guard/auth-guard.service';
import { AuthService, getToken } from 'services/auth-service/auth.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';
import { IdleService } from './shared/services/idle.service';
import { RoleGuard } from './guards/role.guard';
import { RoleService } from './core/services/role.service';
import { StoreModule } from '@ngrx/store';
import {
  TRANSLOCO_DATE_TRANSFORMER,
  TranslocoDatePipe,
} from '@ngneat/transloco-locale';
import { AuthRedirectService } from 'services/auth-guard/auth-redirect.service';
import { MatNativeDateModule } from '@angular/material/core';
import { AlertService } from 'services/alert-service/alert.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { WorkingContextService } from 'services/working-context-service/working-context.service';
import { UsersService } from './modules/accounts/services/users.service';
import { CustomDateServiceService } from 'services/custom-date-service/custom-date-service.service';
import { BarSelectorService } from './shared/services/bar-selector.service';
import { MonitoringService } from './shared/logging/logging.service';
import { ErrorHandlerService } from 'services/error-handler/error-handler.service';
import { roleReducer } from './store/reducers/role.reducer';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { flagsReducer } from './shared/FeatureFlags/store/feature-flag.reducer';
import { FeatureFlagService } from './shared/FeatureFlags/services/feature-flag.service';

function initializeContext(working: WorkingContextService) {
  return (): Promise<any> => {
    return working.load();
  };
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    MatSnackBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslocoRootModule,
    TableauModule,
    LayoutModule,
    MatNativeDateModule,
    MatMomentDateModule,
    StoreModule.forRoot({
      roles: roleReducer,
      flags: flagsReducer,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return getToken();
        },
      },
    }),
  ],
  providers: [
    UsersService,
    CustomDateServiceService,
    BarSelectorService,
    AuthGuardService,
    AuthRedirectService,
    IdleService,
    RoleGuard,
    RoleService,
    FeatureFlagService,
    AuthService,
    AlertService,
    WorkingContextService,
    MonitoringService,
  
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      deps: [WorkingContextService],
      useFactory: initializeContext,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
    provideMomentDateAdapter(undefined, {useUtc: true})
  ],
})
export class AppModule {}
