import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent {
  title = 'web';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {



    this.matIconRegistry.addSvgIcon(
      `plus-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/plus-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dashboard-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/dashboard-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orders-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/orders-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `reports-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/reports-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `dashboard-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/dashboard-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orders-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/orders-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `menu-dots-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/menu-dots-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `reports-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/reports-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `hamburger-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/hamburger-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `search-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/search-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `main-logo-mobile`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/main-logo-mobile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `close-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/close-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `logo-login`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/logo-login.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `opne-in-tab`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/open-in-new.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `accounts-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/accounts-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `accounts-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/accounts-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `padlock-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/padlock-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sustainability-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/sustainability-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `sustainability-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/sustainability-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `info-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/info-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `search-bar-close-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/search-bar-close-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `user-svgrepo-com`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/user-svgrepo-com.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `drop-down-arrow-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/drop-down-arrow-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `documents-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/documents-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `documents-icon-active`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/documents-icon-active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `upload-file-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/upload-file-icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `download-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/download-icon.svg")
    );
  }

}
